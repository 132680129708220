import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import HeadingBg from "@images/slider-img1.jpg"
import PageHeading from "@components/page-heading"
import { Container, Row, Col } from "reactstrap"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Potency = () => {
  return (
    <Layout>
      <SEO title="Potency – HPLC" description="" />
      <PageHeading heading="Potency – HPLC" BgImg={HeadingBg} />
      <div className="page-section">
        <Container>
          <Row>
            <Col lg="6" className="d-flex align-items-center">
              {" "}
              <div>
                <h3 className="my-0 sub-heading">POTENCY TESTING</h3>
                <h2 className="mt-0 mb-4"> Potency testing is important for the safety and knowledge of the consumer.</h2>
                <p>
                We test for the 13 most commonly found cannabinoids in marijuana.  Testing is available at high and low levels, allowing for reporting of low level analytes, giving the consumer a better informed decision when factoring in entourage effects.     
High-performance liquid chromatography with diode-array detection (HPLC-DAD)
CBC, CBD, CBDa, CBDV, CBG, CBGa, CBN, D-8THC, D9-THC, D10-THC, Exo-THC, THCa, THCV

                </p>
              </div>
            </Col>

            <Col lg="6">
              <StaticImage
                src="../../images/terpene-page-img.jpg"
                alt="Terpenes Testing"
                quality={100}
                style={{ borderRadius: "10px" }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default Potency
